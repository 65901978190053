import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/317f3503/src/components/layouts/index.js";
import { colors } from "../../packages/theme/src";
import MainColors from "../components/MainColors";
import ColorPalettes from "../components/ColorPalettes";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Chart colors`}</h1>
    <p>{`Ds-kit contains a set of seven colors that can be used in visualisations, including line charts, maps, scatter plots, area charts, bar charts among others. `}</p>
    <p>{`To determine how you should use color when creating visualisation, it's useful to consider two main categories:`}</p>
    <ol>
      <li parentName="ol">{`Distinctive colors to represent categorical data.`}</li>
      <li parentName="ol">{`Color gradients to represent continuous data`}</li>
    </ol>
    <h2>{`Distinctive colors`}</h2>
    <p>{`Distinctive colors should be used when color represents different categories in the dataset, such as male and female. As a general rule, try to use a maximum of 7 colors in a chart. `}</p>
    <p>{`From the Datastory color palette, the colors most suited for visualisations are those in the 300 and 600 ranges.`}</p>
    <h3>{`300 color range`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`
["yellow.300", "green.300", "cyan.300", "orange.300", "purple.300"]

["#ffc830", "#57d298", "#2bbaff", "#ff713f", "#972bf1"]

`}</code></pre>
    <h3>{`600 color range`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`
["cyan.600", "orange.600", "green.600", "purple.600", "yellow.600"]

["#0073aa", "#bb3000", "#238657", "#57009f", "#af8000"]

`}</code></pre>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`Preference should be given to the 5 colors in the 300 range listed above. Colors from the 300 and 600 ranges can be mixed in cases when  more than 5 colors are needed or if suited better to a specific chart.`}</em></p>
    </blockquote>
    <h3>{`Examples`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1f33236b9339e6b131039785b9dd1d17/91981/color-example-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100.91575091575092%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAAEwUlEQVQ4y02UaUwVVxTHJ41Nk6aJ/dj2axPbj6aJjTHVqG2o6ZbUqlGrSV1oatGC0kpBYhRUsIgYl4iNVpQdFLGCKEsRBFsEWRQUlEWBsgiPt857s7yZX897IPVOzpzcM/f+55zz/9+r8DQDuqOxumPFdk37Rzsw+o8S1JwYnVHQHAH314GrHU0LMNKcxr3cFTRcXEX/jdX0lm6hc+9WWlOjUdTHB1A7NhHs2o7VFSW2HbszEr0vHY/ug5ECmjtjyWz+jj73IywLrt+I49jR+WSeXEJu9hLyMz6h6rP3qPh2EUr80C02DpTjZHpYM94UG/M6MMRf6qxjY9k+7jy6BYZFTOU5PipN4oubR1hYmMzmEzGMrn2f7m3LUFrrs2m8lob/9mWshlLMxquYtYWYw08Iel3YAmqe2om+dT5axjbw+0koTmfh+Wg+L9nLx0W/suZMAgdjdhH/4w8onEuApFVwYDUkfwMH10C89Oyfq4xJuqaucb79OStKBllb+ZweF8QVn+CDI5uJyI7n09xYIrLSeGd/GW9uSkGJLusm4nwrX2XfF+vgy5wHRFzo4HRjPw6fBrbF4bvjvHGsnUX5T3BJKConjXmp61l2OZHFeTEs+eMgb+/MYu7q3SiR5W18mF3P0oI6+VhPRG4Ny86Vs7uyDbcvgK4blLUPEHmxjpSKVnyqwdnaq+wsyiCx7Ax7rp4k6XohOy5UszU9C6Xqfiq1NzbQn7OStivrGDv7NWb6UtobDjEuzJhmEC1oh4nySwuGvAa6aWEbfiwjgM/rwQ4GZWEAzTBQ2s4m83f8Fg6fyGM8YS15cbvYEJlAQ+Y+vAIwqgaZDJhMqjqqboaBdQGwrdBcwy8x0zTEG3hlrqxsgner4K0amJfZzpw9f6L8dIXFp+qkf0GCts2LYYceO/SeHt6AhtMjSrBMDPmJO6CiRJQOMjezh9cTr6HEFPBKbCHKjhyWn6qGoKhQ/hrCtJkxmViWgeqfEtFK+bouffXjkdI1PYCyIL0CZXs2c34u5NVfinltd7FQn8/y0zXSOw+GoWL7XVimJ5yxbRsSN3D5NcETYMPkmVsN90/1S4bzUspRvs9GicpD2RbyuShbslhwtELyMRBKZLEXv+qSbNwEDJeUp6FqQQKajicgZMgRtXQ/QTmXSlZTP8k3Ozhc3UlKTTep1Q9IquyioPWZLLAZnlLx+ELlBVE9E2gBX7hULK84P07VLRXIJSJqMKUnCs5rMJgIkzkwdQnGT8utIjF7NNyz/XlFHDoTh7O3SNj0TZMTuiGMKQHx4zB00ZMvzHZA1KP86x2mbbyFEdcA5SNNNI7e5bGzX47YMB4p9cLth7R33UHzDBMYG8ZwOWZZlwKY9Gro1v86UHr9DqocfTwVkR7rq6J4/AFNvjFa3EP89bSN402VdE2NhZePXs/BcTNfWJ5BeCEpe0ZO4ZLHB+HxPSj/HQp/g7oiuHKcUHxCU7nce5sex0B4X2Cwh4maS7M6tGeB7Nm58tyn0zvu4kl3N7X1d2jpeUZLeyf9Dp9stDBFIt2TAUp6HCIRkY2I94UeXwadBWwYdJPeNEJybR8r81rZWz/E+pKH5D+c7pURtGgeclI/6GFCNWY3vuxfHv8B3JdFFeLgUNIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Color example visualisation",
            "title": "Color example visualisation",
            "src": "/static/1f33236b9339e6b131039785b9dd1d17/f570d/color-example-1.png",
            "srcSet": ["/static/1f33236b9339e6b131039785b9dd1d17/0783d/color-example-1.png 240w", "/static/1f33236b9339e6b131039785b9dd1d17/782f4/color-example-1.png 480w", "/static/1f33236b9339e6b131039785b9dd1d17/f570d/color-example-1.png 960w", "/static/1f33236b9339e6b131039785b9dd1d17/8d0ff/color-example-1.png 1440w", "/static/1f33236b9339e6b131039785b9dd1d17/a987b/color-example-1.png 1920w", "/static/1f33236b9339e6b131039785b9dd1d17/91981/color-example-1.png 2184w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`
["yellow.300", "green.300", "cyan.300", "orange.300", "cyan.600"]

["#ffc830", "#57d298", "#2bbaff", "#ff713f", "#0073aa"]

`}</code></pre>
    <h2>{`Color gradients`}</h2>
    <p>{`Color gradients should be used when showing data that progresses from low to hight or vice versa such as GDP per capita on a map. In this case, the color intensity, or shade is used to indicate a higher or lower value.`}</p>
    <h3>{`One color gradient`}</h3>
    <p>{`The colors in  ds-kit are built in a way that allows to easily create gradients in any of the seven colors.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a2890e1d5e7628a9ac2eafecc10ad3f4/2f950/color-gradient.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "6%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAYAAADeko4lAAAACXBIWXMAAAsSAAALEgHS3X78AAAAXElEQVQI1wFRAK7/AOLe+P/SyfP/wbXu/7Gg6f+gi+T/j3bf/39i2v9uTdX/XjjQ/00jzP9AFMP/OhGz/zUQo/8vDpL/Kg2C/yULcf8fCWH/GghR/xUGQP8PBDD/m9AucR4mKhsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Color gradient example",
            "title": "Color gradient example",
            "src": "/static/a2890e1d5e7628a9ac2eafecc10ad3f4/f570d/color-gradient.png",
            "srcSet": ["/static/a2890e1d5e7628a9ac2eafecc10ad3f4/0783d/color-gradient.png 240w", "/static/a2890e1d5e7628a9ac2eafecc10ad3f4/782f4/color-gradient.png 480w", "/static/a2890e1d5e7628a9ac2eafecc10ad3f4/f570d/color-gradient.png 960w", "/static/a2890e1d5e7628a9ac2eafecc10ad3f4/8d0ff/color-gradient.png 1440w", "/static/a2890e1d5e7628a9ac2eafecc10ad3f4/2f950/color-gradient.png 1600w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b0de590b8fb0d3066b8c050ddae3cb22/941e7/blue-map.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACLUlEQVQoz21TTWsTURTt2l/gVty514UrF7qzdOGioLgNikhxF0SrUIsijZsEpSW2LqRiDUlDacEGGq2p1ZY2oYlRQzJJqEmcTD4n6XzmzembN5Ov6oWZ4c2797x7zrlvxDAMnAy+KWE1msPSdhov1+OoiDJ0AhCWauXr5N86M0bMl6yo+Lqzh9+HAoK7HBbCSQR2OFx3h3Bx0o+WokHSCMqiClk3UGh2sMkpiBU0cFWdrUWFwGyOAe7GfmDccQ+XnwRx4fEq3OtJ2mEeG4k/SPMiOzmareLNxi98TGlYTkjwH0h4H5MQiMsIpSh4UesDyrLMipyzQZy55sTog3k430agdQj7L9BtbySPV2sHCCQ1uLaO8DqqIJxR4PrWRqam9ymbqIRYhS88szh1+iyu3riFar1JNbN02ucEuP2fsRItYCZSw8RyDmOLWVzx1TG51aaSWHRZh4OAvFDBc48X+UJpSOi54BecuzmFRys/8TAQR4gadvvZPO4u7uHTocpyyCAg827A7e9pATPeJVRqDbZe205g4t0+nm7WEOFEKKqKRrOFslBDsVxnjltsbA270bE7NV07P+bApdFxqJqlT5KXcedDCplSvZdvOi7KHZTEDtqq0deQPXaXhg3qmF7A/WmXXUpQagOecJZqKw4dnuR1+Kjjfylobw670aXdaEuYmvMxavYG7YageDQsj/k15y9HXW7KA3N4ErAlKcjzln6mYYOXqcfoPzfM1PEYfhogpPkk9y0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Blue map",
            "title": "Blue map",
            "src": "/static/b0de590b8fb0d3066b8c050ddae3cb22/f570d/blue-map.png",
            "srcSet": ["/static/b0de590b8fb0d3066b8c050ddae3cb22/0783d/blue-map.png 240w", "/static/b0de590b8fb0d3066b8c050ddae3cb22/782f4/blue-map.png 480w", "/static/b0de590b8fb0d3066b8c050ddae3cb22/f570d/blue-map.png 960w", "/static/b0de590b8fb0d3066b8c050ddae3cb22/8d0ff/blue-map.png 1440w", "/static/b0de590b8fb0d3066b8c050ddae3cb22/a987b/blue-map.png 1920w", "/static/b0de590b8fb0d3066b8c050ddae3cb22/941e7/blue-map.png 2496w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
This map is using the Cyan color palette and goes from `}<inlineCode parentName="p">{`cyan.25`}</inlineCode>{` for the lightest shade to `}<inlineCode parentName="p">{`cyan.900`}</inlineCode>{` for the darkest shade. The same can be done for any of the other color palettes.`}</p>
    <p>{`If you are using a tool like `}<inlineCode parentName="p">{`chroma.js`}</inlineCode>{` to generate a full gradient, you need to pass three colors (color.25, color.500, color.900).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { colors } from "@ds-kit/theme"

chroma.scale([
  colors["cyan.25"],   // light limit
  colors["cyan.500"],  // mid color
  colors["cyan.900"],  // dark limit
])
`}</code></pre>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`If you skip the middle value (color.500) you will get a dull gradient,  as the lightest and darkest shades are quite desaturated.`}</em></p>
    </blockquote>
    <h3>{`Two-hue gradients`}</h3>
    <p>{`In some cases  it might be more effective to go from a light color hue to another color's dark hue. This kind of gradient makes use of two variables: lightness and hue rather than just lightness. This can make it easier to decipher gradient maps.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c40bf3bdbb462eaad56f4220891d31bc/941e7/gradient-map-green-blue.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACWElEQVQoz22SS08TURiGh4V/wLhzgyYmLkyMCRo2Etm4YmNcqAkaEFTwwsYuTCRxQUo02ioQJIYEKCkgqRciRhRELsFKQRBEKIRLWmuhUCpQh9bOtPM4nXZaTDiZZM45837Peb93jsAuIxAV6fVMUt3UgqHiIZ5lX+KDoqiPok03xdBupQhxwUbwD312B4sbK3Svfse24qDr1ziHc/LIyDxCYHMzcVBYZMHrp33gKzkGE6U1bdR29mMbHGPGvUxMZQlxYUfPILn5RZRNNVHmacG2PoIjsoTtXTdT8/NIcZi0xdmqp+w5fQMh9wrC8YsIR88hZBdw4FI5V6ta0sBQOKw5qPhgJctYwuXXZh7PvkUm0d7E7wAFluccumXg5L1q6u12SixWip41k32/EuuAA0mOpluOxWLa4o7RjJCxjxNnLuBTIUoyF3d4nUnRT9e6l+LGekramrj+uYG73nZqXe8JhhJ5ag7jwGg0Qff6VrltfMSc250KWYxEaHROY+ropLCmktL2KoxtLzlVdJP8BhNfAgspmKIDNZeKkoJYB0cwv+nVWpZkmVfOKY4VGxD2Z1FqbtA0az4/vSPT9E8vsq22K6s6reWdv1xKOrV8c2IaH8Uf3UrttX60c/B8IcPOHyn9WlBkYslDx6chXN6VdIb6/dLvmBiVyauzsBBYTRUPzbqoHR1WPce0zOXkQeUPniDszaSzpy/tUAfpb38wyLW6ZsKS9N++HFMhSnodVcEz84vUt75gYmaOrVAk6TBZpM8D2yJjP13JsGNpqJLWKTsy18d2+C//AH5m+h3nWJBTAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Blue green map",
            "title": "Blue green map",
            "src": "/static/c40bf3bdbb462eaad56f4220891d31bc/f570d/gradient-map-green-blue.png",
            "srcSet": ["/static/c40bf3bdbb462eaad56f4220891d31bc/0783d/gradient-map-green-blue.png 240w", "/static/c40bf3bdbb462eaad56f4220891d31bc/782f4/gradient-map-green-blue.png 480w", "/static/c40bf3bdbb462eaad56f4220891d31bc/f570d/gradient-map-green-blue.png 960w", "/static/c40bf3bdbb462eaad56f4220891d31bc/8d0ff/gradient-map-green-blue.png 1440w", "/static/c40bf3bdbb462eaad56f4220891d31bc/a987b/gradient-map-green-blue.png 1920w", "/static/c40bf3bdbb462eaad56f4220891d31bc/941e7/gradient-map-green-blue.png 2496w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This map uses light greens and dark blues to create a two-color gradient. The same approach can be used with two other colors such as light yellow to dark orange.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { colors } from "@ds-kit/theme"

chroma.scale([
  colors["green.25"],  // lightest green
  colors["green.400"], // mid green
  colors["cyan.700"],  // dark cyan
  colors["cyan.900"],  // very dark cyan
])
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      